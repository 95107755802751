import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Main from './compoent/Main';
import Login from './compoent/Login';


const App = () => {
  const [isLogin, setIsLogin] = useState(false);
  const location = useLocation();

  const checkLoginStatus = () => {
    const token = sessionStorage.getItem("authToken");
    setIsLogin(!!token);  // Convert to boolean: true if token exists, false otherwise
  };

  useEffect(() => {
    checkLoginStatus();
  }, [location]); // Check login status on every route change

  return (
    <Routes>
      {isLogin ? (
        <>
          <Route path="/" element={<Main />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </>
      ) : (
        <>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </>
      )}
    </Routes>
  );
};

const AppWrapper = () => {
  return (
    <Router>
      <App />
    </Router>
  );
};

export default AppWrapper;
