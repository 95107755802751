import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import userImg from '../Image/user.jpg'
import '../css/main.css'
import '../ApiInterceptor';
import '../App.css'
import { useNavigate } from 'react-router-dom';
import { Oval, ThreeDots } from 'react-loader-spinner';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography } from '@mui/material';
import { marked } from 'marked';

marked.setOptions({
    sanitize: true,
});
function Main() {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [aiTyping, setAiTyping] = useState(null);
    const messagesEndRef = useRef(null);
    const [selectedTab, setSelectedTab] = useState(null);
    const [disabled, setDisabled] = useState(false);
    const [modelList, setModelList] = useState([]);
    const [perloader, setPerloader] = useState(false);
    const [pdfUrl, setPdfUrl] = useState({ url: '', no: '' });
    const [modalOpen, setModalOpen] = useState(false);
    const userName = sessionStorage.getItem('userName');
    const navigate = useNavigate();
    const handleTabClick = (tab) => {
        if (disabled) return true;
        setSelectedTab(tab);
    };

    const logout = async () => {
        sessionStorage.removeItem('authToken');
        sessionStorage.removeItem('userName');
        navigate("/login")
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(disabled, "disable");

        if (input == '' || disabled) return true;
        const userMessage = { sender: 'user', text: input };
        setMessages([...messages, userMessage]);
        setDisabled(true);
        setInput('');
        setPerloader(true);
        scrollToBottom();
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/query`, {
                question: input,
            });

            const aiText = response?.data?.results?.[selectedTab]?.answer;
            setPerloader(false)
            setAiTyping({ sender: 'ai', text: '' });
            await typeAiResponse(aiText, response?.data?.results);
            scrollToBottom();
        } catch (error) {
            console.error('Error communicating with AI:', error);
            setPerloader(false)
        } finally {
            setDisabled(false)
        }
    };

    const typeAiResponse = (text, originText) => {
        return new Promise((resolve) => {
            let index = 0;
            const typingSpeed = 15; // Adjust typing speed here
            setAiTyping({ sender: 'ai', text: text.charAt(0) });

            const interval = setInterval(() => {
                if (index < text.length) {
                    setAiTyping((prev) => ({
                        sender: 'ai',
                        text: prev.text + text.charAt(index),
                    }));
                    index++;
                    scrollToBottom();
                } else {
                    clearInterval(interval);
                    setMessages((prevMessages) => [
                        ...prevMessages,
                        { sender: 'ai', text: originText },
                    ]);
                    setAiTyping(null);
                    resolve(); // Resolve the promise after typing is complete
                }
            }, typingSpeed);
        });
    };

    const getModel = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/model-keys`);
            setModelList(response?.data?.model_keys)
            setSelectedTab(response?.data?.model_keys?.[0])
        } catch (error) {
            console.error(error);

        }
    }

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const renderMessage = (text) => {
        const parsedHtml = marked(text);
        return (
            <div
                dangerouslySetInnerHTML={{
                    __html: parsedHtml,
                }}
            />
        );
    };

    const handleSources = async (name) => {
        setModalOpen(true)
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/get-pdf-blob/`, {
                pdf_file_name: name?.filename
            }, {
                responseType: 'blob'  // This is critical
            });
            // Create a URL for the blob
            console.log('Response type:', response.data instanceof Blob);

            console.log(response?.data);
            const blobUrl = URL.createObjectURL(response.data);
            // Set the URL in state
            setPdfUrl({ url: blobUrl, no: name?.page_no });
        } catch (error) {
            console.error(error);

        }
    }

    const handleClose = () => {
        setPdfUrl({ url: '', no: '' })
        setModalOpen(false)
    }

    useEffect(() => {
        getModel()
    }, [])

    useEffect(() => {
        console.log(messages);

    }, [messages])

    return (
        <div className="d-flex vh-100">
            <div className="d-flex flex-column w-100">

                <div className="d-flex flex-grow-1">

                    <div className="d-flex flex-column bg-light col-5" style={{ width: '260px', borderRight: '1px solid #ddd', }}>
                        <div className="p-3 pb-1">
                            <h5 className='text-center'>GPT Ifrost </h5>
                        </div>

                    </div>

                    <div className="flex-grow-1 d-flex flex-column  pe-0">
                        <div className="d-flex justify-content-between align-items-center px-3 py-1  " >
                            <div className="d-flex">
                                {
                                    modelList?.map((ele) => (
                                        <div
                                            className={`tab ${selectedTab === ele ? 'active' : ''}`}
                                            onClick={() => handleTabClick(ele)}
                                        >
                                            {ele}
                                        </div>
                                    ))
                                }
                            </div>

                            <div className="d-flex align-items-center me-4">
                                <span className="me-3">Welcome, {userName || 'User'}</span>

                                <div class="dropdown">
                                    <img
                                        data-bs-toggle="dropdown" aria-expanded="false"
                                        src={userImg}
                                        className="rounded-circle"
                                        style={{ width: '40px', height: '40px' }}
                                    />
                                    <ul class="dropdown-menu">

                                        <li><a class="dropdown-item" ><i class="fa-solid fa-gear me-2"></i>Settings</a></li>
                                        <li onClick={logout}><a class="dropdown-item" ><i class="fa-solid fa-right-from-bracket me-2"></i>Log Out</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="flex-grow-1 overflow-auto mb-3 px-5" style={{ borderRadius: '8px', height: '70vh', padding: '20px' }}>
                            {messages.map((msg, index) => (
                                <>
                                    <div
                                        key={index}
                                        className={`d-flex mb-2 ${msg.sender !== 'user' ? 'justify-content-start' : 'justify-content-end'}`}
                                    >
                                        <div
                                            className={`px-3 py-2  ${msg.sender !== 'user' ? ' text-dark' : 'user-text text-dark'}`}
                                            style={{ maxWidth: '90%', borderRadius: '16px' }}
                                        >
                                            {
                                                msg.sender !== 'user' ? renderMessage(msg.text?.[selectedTab]?.answer || "") : renderMessage(msg?.text)
                                            }
                                        </div>
                                    </div>
                                    {
                                        msg?.text?.[selectedTab]?.sources ?
                                            <div className="d-flex justify-content-center">
                                                {
                                                    msg?.text?.[selectedTab]?.sources?.map((ele) => (

                                                        <div className="card shadow-sm mb-3 mx-2 cursor-pointer" onClick={() => { handleSources(ele) }}>
                                                            <div className="card-body source-card-body bg-light d-flex align-items-center">

                                                                <div>
                                                                    <p className="card-title source-card-text">{ele?.filename}</p>
                                                                    <p className="card-title source-card-text">Page No : {ele?.page_no}</p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    ))
                                                }
                                            </div>
                                            : null
                                    }

                                </>
                            ))}
                            {aiTyping && (
                                <div className="d-flex jjustify-content-start mb-2">
                                    <div className="px-3 py-2 rounded  text-dark" style={{ maxWidth: '90%' }}>
                                        {renderMessage(aiTyping.text)}
                                    </div>
                                </div>
                            )}
                            {
                                perloader && <ThreeDots
                                    className="ms-3"
                                    visible={true}
                                    height="40"
                                    width="40"
                                    color="#172E55"
                                    radius="9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                />
                            }
                            <div ref={messagesEndRef} />
                        </div>
                        <form onSubmit={handleSubmit} className="d-flex px-5 pb-3">
                            <input
                                type="text"
                                className="form-control"
                                value={input}
                                onChange={(e) => setInput(e.target.value)}
                                placeholder="Type a message..."
                            />
                            <button type="submit" disabled={disabled} className="btn btn-primary ms-2">Send</button>
                        </form>
                    </div>
                </div>
                {
                    modalOpen && (
                        <Dialog
                            open={modalOpen}
                            onClose={() => { handleClose() }}
                            sx={{ '& .MuiDialog-paper': { width: '80%', maxWidth: '800px' } }}
                            aria-labelledby="popup-modal-title"
                            aria-describedby="popup-modal-description"
                        >
                            <div style={{ width: '100%', height: '500px' }}>
                                {pdfUrl?.url ? (
                                    <iframe
                                        src={`${pdfUrl?.url}#page=${pdfUrl?.no}`}
                                        title="PDF Viewer"
                                        width="100%"
                                        height="100%"
                                        style={{ border: 'none' }}
                                    />
                                ) : (
                                    <div className='d-flex align-items-center justify-content-center w-100 h-100'>
                                        <Oval
                                            visible={true}
                                            height="80"
                                            width="80"
                                            color="#4fa94d"
                                            ariaLabel="oval-loading"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                        />
                                    </div>
                                )}
                            </div>
                            <DialogActions>
                                <Button onClick={() => { handleClose() }} color="primary">
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )
                }
            </div>
        </div>
    )
}

export default Main