import axios from 'axios'; 

// Request interceptor

axios.interceptors.request.use(
  config => {
    const token = sessionStorage.getItem('authToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// Response interceptor
axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response && error.response.status === 401) {
      sessionStorage.removeItem('authToken');
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

export default axios;
